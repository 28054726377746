body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Fontello";
  src: url("./assets/fontello.woff") format("woff");
}

i.icon.custom {
  font-family: "Fontello";
  font-size: 1em;
  font-weight: bolder;
}

i.icon.paintbucket:before {
  content: "\e800";
}

i.icon.straight-line:before {
  content: "\e801";
}

i.icon.dashed-line:before {
  content: "\e802";
}

i.icon.diamondshape:before {
  content: "\e803";
}