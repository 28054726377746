body {
  background-color: #4f5b62;
}

.ui.table {
  border: solid #4f5b62 1px;
}

.ui.segment {
  background-color: #263238;
}

.icon.button {
  background-color: #263238;
}

.indicator,
.table.indicator {
  background-color: #263238;
  border-color: #263238;
}

.labeled.button {
  color: white;
}

i.icon:before {
  color: white;
}

.icon.button:hover i.icon:before {
  color: #263238;
}


.ui.button:focus {
  background-color: transparent;
}


.canvasCell {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}
